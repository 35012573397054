import React, {useState} from 'react';
import profilethumb from '../../assets/images/profile-img.png';
import {MoonIcon, NotificationIcon, SunIcon} from '../../constants/svg'
import {Switch} from "antd";

const TopHeader = (props) => {
    console.log(props, "props");
    const [isDarkMode, setIsDarkMode] = useState(false);

    const handleToggle = () => {
        setIsDarkMode(!isDarkMode);
        document.body.classList.toggle('dark', !isDarkMode);
        document.body.classList.toggle('light', isDarkMode);
    };

    return (
        <>
            <div className="topheaderbar">
                <div className="top-drawer-area">

                    <div className="switchIcon">
                        <Switch
                            checkedChildren={<SunIcon/>}
                            unCheckedChildren={<MoonIcon/>}
                            defaultChecked
                            size="large"
                            onChange={handleToggle}
                        />
                    </div>
                    <div className="NotificationIcon">
                        <span className="StatusOnline"></span>
                        <NotificationIcon/>
                    </div>
                    <div className="profileThumb"><img src={profilethumb} alt={"profile img"}/></div>
                    <div className={props.humburger ? "hamburger-icon active" : "hamburger-icon"}
                         onClick={props.onHumburgerClick}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>


        </>
    )
}

export default TopHeader