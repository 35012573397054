import React from 'react';
import {Steps, Tag} from 'antd';
import {CircleTick, EmailRounded, ExclaimationMark, PhoneRounded} from "../../constants/svg";

const {Step} = Steps;

const stepsData = [
    {
        title: 'Job Title',
        description: 'UX Consultant',
        matchCriteria: '50%', // Set match criteria for each step
    },
    {
        title: 'Location',
        description: 'Karachi, Pakistan',
        matchCriteria: '100%',
    },
    {
        title: 'Experience',
        description: '2 years',
        matchCriteria: '100%',
    },
    {
        title: 'Occupation Group',
        description: 'UX Design, CX, UXD',
        matchCriteria: '100%',
    },
];

const stepsDataSecond = [
    {
        title: 'Education',
        description: <p>Bahria University <br/>Bachelor of Software Engineering <br/>2012 - 2016</p>,
        matchCriteria: '100%', // Set match criteria for each step
    },
    {
        title: 'Skills',
        description: ["MERN Stack", "Business", "Project Manager", "Brand", "Business"],
        matchCriteria: '100%',
    },
    {
        title: 'Management Level',
        description: 'Low',
        matchCriteria: '100%',
    }
];

function getStatus(matchCriteria) {
    return matchCriteria ? 'finish' : 'wait'; // Set status based on match criteria
}

function ResumeDetail(props) {
    const description = 'This is a description.';
    return (

        <>

            <div className="list-resume">
                <div className="candidateDetail">
                    <div className="candidateBox">
                        <div className="icon">
                            <EmailRounded/>
                        </div>
                        <div className="para">
                            <h5>EMAIL</h5>
                            <p>malaika.br@gmail.com</p>
                        </div>
                    </div>
                    <div className="candidateBox">
                        <div className="icon">
                            <PhoneRounded/>
                        </div>
                        <div className="para">
                            <h5>Phone</h5>
                            <p>+11 5423-6548</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Steps current={0} direction="vertical">

                            {stepsData.map((step, index) => (
                                <Step
                                    direction="vertical"
                                    key={index}
                                    title={step?.title}
                                    icon={step?.matchCriteria === '100%' ? <CircleTick/> : <ExclaimationMark/>}
                                    description={
                                        <div className="stepContent">
                                            {step.description}
                                            <div
                                                className={step?.matchCriteria === '100%' ? "match-percent success" : "match-percent"}>{step.matchCriteria} Match
                                            </div>
                                        </div>
                                    }
                                    status={getStatus(step.matchCriteria === '100%' ? true : false)}
                                />
                            ))}
                        </Steps>
                    </div>
                    <div className="col-md-6">

                        <Steps current={0} direction="vertical">

                            {stepsDataSecond.map((step, index) => (
                                <Step
                                    direction="vertical"
                                    key={index}
                                    title={step?.title}
                                    icon={step?.matchCriteria === '100%' ? <CircleTick/> : <ExclaimationMark/>}
                                    description={
                                        <div className="stepContent">

                                            {Array.isArray(step.description) ? (
                                                step.description.map((tag, index) => (
                                                    <Tag key={index}>{tag}</Tag>
                                                ))
                                            ) : (
                                                <p>{step.description}</p>
                                            )}
                                            <div
                                                className={step?.matchCriteria === '100%' ? "match-percent success" : "match-percent"}>{step.matchCriteria} Match
                                            </div>
                                        </div>
                                    }
                                    status={getStatus(step.matchCriteria === '100%' ? true : false)}
                                />
                            ))}
                        </Steps>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResumeDetail;