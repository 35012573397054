import React, {useState} from 'react'
import MainSidebar from '../components/DashboardLayout/MainSidebar'
import TopHeader from '../components/DashboardLayout/TopHeader'
import MainSection from "../components/Dashboard/MainSection";
import Sidebar from "../components/Dashboard/Sidebar";

function DashboardLayout() {
    const [humburger, setHumburger] = useState(false);
    const handleHamburger = () => {
        console.log("clicked");
        setHumburger(!humburger);
    };
    return (
        <>
            <div className="mainAppWrapper">
                <MainSidebar humburger={humburger}/>
                <div className="dashboard-layout">
                    <TopHeader humburger={humburger} onHumburgerClick={handleHamburger}/>
                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="col-md-3">
                                <Sidebar/>
                            </div>
                            <div className="col-md-9">
                                <MainSection/>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </>
    )
}

export default DashboardLayout