import React from 'react'
import {LogoutIcon, MainLogo, SettingIcon, UserIcon} from '../../constants/svg'
import {NavLink} from 'react-router-dom'

const MainSidebar = (props) => {
    return (
        <>
            <div className={props.humburger ? "sidebarmain active" : "sidebarmain"}>
                <div className="sidebarWrapper">
                    <NavLink to="/"> <MainLogo/></NavLink>
                    <ul>
                        <li><NavLink to="/"> <UserIcon/></NavLink></li>
                        <li><NavLink to="/setting"> <SettingIcon/></NavLink></li>
                    </ul>

                </div>

                <LogoutIcon/>
            </div>
        </>
    )
}

export default MainSidebar