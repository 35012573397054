import React, {useState} from 'react'
import {Checkbox, Collapse, Input, Select, Slider, Switch, Tag} from 'antd';
import {ArrowDown} from "../../constants/svg";

const {TextArea} = Input;

const {Panel} = Collapse;
const OPTIONS = ['Resume 1', 'Resume 2 ', 'Resume 3', 'Resume 4'];
const suggestedJobTitles = ['MERN Stack', 'Business', 'Project Manager', 'Brand', 'Business'];
const Sidebar = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [isJobTitleOpen, setIsJobTitleOpen] = useState(false);
    const [jobTitlesSuggestion, setJobTitlesSuggestion] = useState(false)
    // ============== Suggested Job Stats ===============

    const handleInputFocus = () => {
        setJobTitlesSuggestion(true);
    };
    const handleBlur = () => {
        setIsJobTitleOpen(false);
    };

    const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
    const handleJobTitleCollapseChange = checked => {
        setIsJobTitleOpen(checked);
    }

    const [isLocationOpen, setIsLocationOpen] = useState(false);
    const handleLocationCollapseChange = checked => {
        setIsLocationOpen(checked);
    }

    const [isExperienceCollapseOpen, setIsExperienceCollapseOpen] = useState(false);
    const handleExperienceCollapseChange = checked => {
        setIsExperienceCollapseOpen(checked);
    }


    const [isJobDescriptionOpen, setIsJobDescriptionOpen] = useState(false);
    const handleJobDescriptionChange = checked => {
        setIsJobDescriptionOpen(checked);
    };


    const [disabled, setDisabled] = useState(false);
    const onChange = (checked) => {
        setDisabled(checked);
    };


    // const [isLocationOpen, setIsLocationOpen] = useState(false);
    // const handleLocationCollapseChange = checked => {
    //     setIsLocationOpen(checked);
    // }


    return (
        <div className='sidebar-section'>
            <div className="searchsidebar">
                <label className="darkPara">Search in</label>
                <Select
                    className="selectcustom"
                    showSearch
                    style={{
                        width: 210,
                        height: 40
                    }}
                    suffixIcon={<ArrowDown/>}
                    placeholder="Resume-Search"
                    optionFilterProp="children"
                    value={selectedItems}
                    onChange={setSelectedItems}
                    options={filteredOptions.map((item) => ({
                        value: item,
                        label: item,
                    }))}
                />
            </div>

            <div className="sidebarJobTitle switchboxarea">
                <Collapse activeKey={isJobTitleOpen ? '1' : null}>
                    <Panel header={<div className="switchlabelarea"><label className={"d-flex"}>
                        <Switch
                            checked={isJobTitleOpen}
                            onChange={handleJobTitleCollapseChange}
                        />
                        <h5>Job Title</h5></label></div>}
                           key="1">
                        <Input placeholder="Add a job title" onChange={handleInputFocus} onBlur={handleBlur}/>
                        {jobTitlesSuggestion && <div className="suggestedTags mb-2">
                            <span className="darkPara me-1">Suggested:</span>
                            {suggestedJobTitles.map((tags, index) => <Tag key={index}>{tags}</Tag>)}
                        </div>}
                        <Checkbox onChange={onChange}>Search most recent job titles only</Checkbox>
                    </Panel>
                </Collapse>
            </div>

            <div className="sidebarLocation switchboxarea">
                <Collapse activeKey={isLocationOpen ? '1' : null}>
                    <Panel header={<div className="switchlabelarea"><label className={"d-flex"}>
                        <Switch
                            checked={isLocationOpen}
                            onChange={handleLocationCollapseChange}
                        />
                        <h5>Location</h5></label></div>}
                           key="1">
                        <Select
                            className="selectcustom"
                            showSearch
                            style={{
                                width: "100%"
                            }}
                            placeholder="Location"
                            suffixIcon={<ArrowDown/>}
                            options={[
                                {
                                    value: 'Location 1',
                                    label: 'Location 1',
                                },
                                {
                                    value: 'Location 2',
                                    label: 'Location 2',
                                },
                                {
                                    value: 'Location 3',
                                    label: 'Location 3',
                                }
                            ]}
                        />
                    </Panel>
                </Collapse>
            </div>


            <div className="sidebarYearsOfExperience switchboxarea">
                <Collapse activeKey={isExperienceCollapseOpen ? '1' : null}>
                    <Panel header={<div className="switchlabelarea"><label className={"d-flex"}>
                        <Switch
                            checked={isExperienceCollapseOpen}
                            onChange={handleExperienceCollapseChange}
                        />
                        <h5>Years Of Experience</h5></label></div>}
                           key="1">

                        <Slider range defaultValue={[0, 100]} disabled={disabled}/>
                    </Panel>
                </Collapse>
            </div>


            <div className="sidebarJobDescription switchboxarea">
                <Collapse activeKey={isJobDescriptionOpen ? '1' : null}>
                    <Panel header={<div className="switchlabelarea"><label className={"d-flex"}>
                        <Switch
                            checked={isJobDescriptionOpen}
                            onChange={handleJobDescriptionChange}
                        />
                        <h5>Job Description</h5></label></div>}
                           key="1">
                        <TextArea rows={4} placeholder="Write your job description here.." maxLength={100}/>
                    </Panel>
                </Collapse>
            </div>

        </div>
    )
}

export default Sidebar
