import React from 'react';
import {Dropdown, Menu, Progress, Table,} from 'antd';
import ResumeDetail from "./ResumeDetail";
import {ArrowDownFilled, VerticalDotted} from "../../constants/svg";

const {Column} = Table;


const data = [
    {
        key: '1',
        name: 'Malaika Brown',
        designation: 'UX Designer',
        location: 'Karachi, Pakistan',
        experience: '6 years',
        occupation: 'Software Developer',
        education: 'Bachelor of Computer Science',
        skills: ['React', 'Node.js', 'JavaScript'],
        match: 100,
    },
    {
        key: '2',
        name: 'Jane Smith',
        designation: 'UX Designer',
        location: 'Los Angeles',
        experience: 8,
        occupation: 'Product Manager',
        education: 'Master of Business Administration',
        skills: ['Product Management', 'Marketing'],
        match: 89,
    },
    {
        key: '3',
        name: 'Jane Smith',
        designation: 'UX Designer',
        location: 'Los Angeles',
        experience: 8,
        occupation: 'Product Manager',
        education: 'Master of Business Administration',
        skills: ['Product Management', 'Marketing'],
        match: 89,
    },
    {
        key: '4',
        name: 'Jane Smith',
        designation: 'UX Designer',
        location: 'Los Angeles',
        experience: 8,
        occupation: 'Product Manager',
        education: 'Master of Business Administration',
        skills: ['Product Management', 'Marketing'],
        match: 89,
    },

    // add more
    // data here...
];

const columns = [
    {
        title: 'Candidate Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => (
            <p>{<strong>{name}</strong>}{`${record.designation}`}</p>
        ),
        align: 'center'
    },

    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        align: 'center',
        sorter: (a, b) => a.location.localeCompare(b.location),
    },
    {
        title: 'Experience',
        dataIndex: 'experience',
        key: 'experience',
        sorter: (a, b) => a.experience - b.experience,
    },
    {
        title: 'Occupation',
        dataIndex: 'occupation',
        key: 'occupation',
        ellipsis: true,
        align: 'center',
        sorter: (a, b) => a.occupation.localeCompare(b.occupation),

    },
    {
        title: 'Education',
        dataIndex: 'education',
        key: 'education',
        ellipsis: true,
        align: 'center',
        sorter: (a, b) => a.education.localeCompare(b.education),


    },
    {
        title: 'Skills',
        dataIndex: 'skills',
        key: 'skills',
        ellipsis: true,
        align: 'center',
        sorter: (a, b) => a.skills.length - b.skills.length,
        render: (skills) => (
            <>
                {skills[0]}

            </>
        ),
    },
    {
        title: 'Match',
        dataIndex: 'match',
        key: 'match',
        align: 'center',
        render: (match) => (
            <>
                <Progress
                    type="circle"
                    percent={match}
                    size={50}
                    format={() => (
                        <span style={{color: '#898989'}}> {match === 100 ? '100%' : `${match}%`}</span>
                    )}
                    strokeColor={match === 100 ? '#00B85E' : '#F37E21'}
                    trailColor="#DDDDDD"
                />
            </>
        ),

    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: () => (
            <span>
                <VerticalDotted/>
            </span>
        ),
    },
];


const MainSection = () => {
    const items = [
        {
            label: "1st menu item",
            key: '0',
        },
        {
            label: "2nd menu item",
            key: '1',
        },
        {
            label: '3rd menu item',
            key: '2',
        },
    ];

    const menu = (
        <Menu>
            <Menu.Item key="1">Option 1</Menu.Item>
            <Menu.Item key="2">Option 2</Menu.Item>
            <Menu.Item key="3">Option 3</Menu.Item>
        </Menu>
    );

    const expandedRowRender = (record) => (
        <div style={{whiteSpace: 'pre-wrap'}}>
            <ResumeDetail/>
        </div>
    );


    return (
        <div className='table-section'>
            <div className="mainSearchBar">
                <div className="searchMatch">
                    <p>8 Matches</p>
                </div>
                <div className="actionBox">
                    <Dropdown overlay={menu}>
                        <div className="dropdownHolder">
                            <p className="m-0">Action</p>
                            <ArrowDownFilled/>
                        </div>
                    </Dropdown>
                </div>
            </div>
            <div className="tabularSection">
                <Table
                    columns={columns}
                    dataSource={data}
                    expandable={{expandedRowRender}}
                    rowSelection={{
                        type: "checkbox"
                    }}
                    bordered={false}
                    hover={false}
                    rowClassName={() => 'no-hover'}
                    pagination={false}
                    scroll={{x: 767}}
                    responsive={true}
                />
            </div>


        </div>
    )
}

export default MainSection