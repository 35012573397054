import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import DashboardLayout from '../layout/DashboardLayout';


const Navigation = () => {
  return (
   <BrowserRouter>
      <Routes>
        {/* SignIn Flow */}


        <Route path="/" element={<DashboardLayout></DashboardLayout>} />
        <Route path="/user" element={<DashboardLayout>user</DashboardLayout>} />
        <Route path="/setting" element={<DashboardLayout>setting</DashboardLayout>} />





        </Routes>
         </BrowserRouter>
  )
}

export default Navigation